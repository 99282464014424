module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-32837914-1","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daniele Bertella","short_name":"DB","start_url":"/","background_color":"#333","theme_color":"#000","display":"minimal-ui","icon":"src/images/pinched-fingers.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f57450cbbc365c061cae269c9c7cb745"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
